import React from "react"
import dashStyles from "../styles/dashboard.module.css"

const DashNav = ({ navigate, logout, settingsNav, dark, messages }) => {
  return (
    <div style={{ position: "relative" }}>
      {/* dash nav component */}
      <div style={{ textAlign: "center", marginTop: "210px" }}>
        
        <p
          className={
            dark
              ? `${dashStyles.dashNav} ${dashStyles.dashNavDark}`
              : `${dashStyles.dashNav}`
          }
          style={settingsNav === "log" ? { opacity: "1" } : {}}
          onClick={() => {
            navigate("log")
          }}
        >
          log
        </p>
        {/* <p
          className={
            dark
              ? `${dashStyles.dashNav} ${dashStyles.dashNavDark}`
              : `${dashStyles.dashNav}`
          }
          style={settingsNav === "projects" ? { opacity: "1" } : {}}
          onClick={() => {
            navigate("projects")
          }}
        >
          projects
        </p> */}

        <p
          className={
            dark
              ? `${dashStyles.dashNav} ${dashStyles.dashNavDark}`
              : `${dashStyles.dashNav}`
          }
          style={settingsNav === "settings" ? { opacity: "1" } : {}}
          onClick={() => {
            navigate("settings")
          }}
        >
          settings
        </p>
        {/* <p
          className={
            dark
              ? `${dashStyles.dashNav} ${dashStyles.dashNavDark}`
              : `${dashStyles.dashNav}`
          }
          style={settingsNav === "photos" ? { opacity: "1" } : {}}
          onClick={() => {
            navigate("photos")
          }}
        >
          photos
        </p> */}
        {/* <p
          className={
            dark
              ? `${dashStyles.dashNav} ${dashStyles.dashNavDark}`
              : `${dashStyles.dashNav}`
          }
          style={settingsNav === "messages" ? { opacity: "1" } : {}}
          onClick={() => {
            navigate("messages")
          }}
        >
          messages{messages.length > 0 ? `(${messages.length})` : ""}
        </p> */}
        <p
          className={
            dark
              ? `${dashStyles.dashNav} ${dashStyles.dashNavDark}`
              : `${dashStyles.dashNav}`
          }
          style={{marginTop: '10px', display: 'block', fontSize: '14px'}}
        >
          {/* <span
            onClick={logout}
            onKeyDown={logout}
            role="button"
            tabIndex="0"
            // className={dashStyles.logout}
            // style={dark ? { color: "#58A6FF" } : { color: "#0366D6" }}
          >
            logout
          </span> */}
        </p>
      </div>
    </div>
  )
}

export default DashNav
