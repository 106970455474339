import React, { useEffect, useState, useContext } from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import Login from "../components/login"
import SEO from "../components/seo"
import Error from "../components/projectError"
import Loader from "../components/loader"
import IndexSettings from "../components/IndexSettings"
import CodeListSettings from "../components/CodeListSettings"
// import Scrollable from "../components/scrollable"
import dashStyles from "../styles/dashboard.module.css"
import workService from "../services/work"
import { updateSettings } from "../services/settings"
import { getLog, setLog } from "../services/log"
import SettingsContext from "../context/SettingsContext"
import ModeContext from "../context/ModeContext"
import DashNav from "../components/DashNav"
import Message from "../components/Message"
import WordsLog from "../components/wordsLog"
import Scrollable from "../components/scrollable"
import PhotoUpload from "../components/PhotoUpload"
import axios from "axios"

const Pad = () => {
  const { settings, getSettings } = useContext(SettingsContext)
  const [hasFocus, setHasFocus] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [user, setUser] = useState(null)
  const [listItem, setListItem] = useState("")
  const [currentWords, setCurrentWords] = useState("")
  const [selectedProjects, setSelectedProjects] = useState([])
  const [settingsNav, setSettingsNav] = useState("log")
  const { dark } = useContext(ModeContext)

  useEffect(() => {
    getSettings()

    const getProjects = async () => {
      setLoading(true)

      try {
        // const currentList = await workService.getList()
        // setSelectedProjects(currentList)

        const loggedInUser = window.localStorage.getItem("loggedInUser")
        if (!loggedInUser) { window.location.replace("/") }
        const loggedInUserJSON = JSON.parse(loggedInUser)
        const isValidUser = await axios.post('https://api.sup.cool/users/auth', { token: loggedInUserJSON.token })
        // console.log(isValidUser)

        if (isValidUser.request.status === 200) {
          const user = JSON.parse(loggedInUser)
          setUser(user)
          const currentLog = await getLog(user.token)
          setCurrentWords(currentLog)
          // console.log(currentWords)
          setLoading(false)
        } else {
          navigate("/")
        }

        const whichDash = window.localStorage.getItem("dash")
        if (whichDash) { setSettingsNav(whichDash) }


      } catch (exception) {
        if (!user) { navigate("/") }
        console.error(exception)
        setError(true)
      }
    }

    getProjects()
  }, []) //eslint-disable-line

  const setFocus = () => {
    setHasFocus(!hasFocus)
  }

  const setCurrentUser = currentUser => {
    setUser(currentUser)
  }

  const logout = () => {
    setUser(null)
    if (window !== "undefined") {
      window.localStorage.removeItem("loggedInUser")
      navigate('/')
    }
  }

  const updateProfilePicSetting = async () => {
    await updateSettings(
      {
        settings: {
          showProfilePic: !settings.showProfilePic,
          showCTA: settings.showCTA,
          showDots: settings.showDots,
          dotVelocity: settings.dotVelocity,
          cta: settings.cta,
          payload: settings.payload,
          id: settings.id,
        },
      },
      user.token
    )
    getSettings()
  }

  const updateCTASetting = async (cta, payload) => {
    await updateSettings(
      {
        settings: {
          showProfilePic: settings.showProfilePic,
          showCTA: !settings.showCTA,
          showDots: settings.showDots,
          dotVelocity: settings.dotVelocity,
          cta: cta !== '' ? cta : '...',
          payload: payload !== '' ? payload : '/',
          id: settings.id,
        },
      },
      user.token
    )
    getSettings()
  }

  const updateDotSetting = async (vel) => {
    await updateSettings(
      {
        settings: {
          showProfilePic: settings.showProfilePic,
          showCTA: settings.showCTA,
          showDots: !settings.showDots,
          dotVelocity: vel,
          cta: settings.cta,
          payload: settings.payload,
          id: settings.id,
        },
      },
      user.token
    )
    getSettings()
  }

  const addListItem = async () => {
    const { work } = await workService.getAll()
    const repos = work.data.map(r => r.name)

    if (repos.includes(listItem) && !selectedProjects.includes(listItem)) {
      const newList = selectedProjects.map(i => i)
      newList.push(listItem)
      console.log(newList)
      await workService.updateProjectList({ list: newList }, user.token)
      setSelectedProjects(newList)
      setListItem("")
    }
  }

  const removeListItem = async () => {
    const { work } = await workService.getAll()
    const repos = work.data.map(r => r.name)

    if (repos.includes(listItem) && selectedProjects.includes(listItem)) {
      const newList = selectedProjects.map(i => i)
      const filteredList = newList.filter(i => i !== listItem)
      await workService.updateProjectList({ list: filteredList }, user.token)
      setSelectedProjects(filteredList)
      setListItem("")
    }
  }

  const postLog = async (words, status) => {
    // post words to db

    setLog(words, status, user.token)
    // console.log(words, status)
  }

  const navigateDash = here => {
    setSettingsNav(here)
    // console.log(here)
  }

  const goHome = () => {
    navigate('/space')
  }

  // 
  if (user) {
    return (
      <Layout hasFocus={hasFocus}>
      <SEO title="pad" />

      <DashNav
        navigate={navigateDash}
        logout={logout}
        settingsNav={settingsNav}
        dark={dark}
      />

      <div className={dashStyles.settingsArea}>
        {/* settings component */}
        {settingsNav === "settings" && (
          <IndexSettings
            settings={settings}
            updateProfilePicSetting={updateProfilePicSetting}
            updateCTASetting={updateCTASetting}
            updateDotSetting={updateDotSetting}
            setFocus={setFocus}
          />
        )}

        {settingsNav === "log" && (
          <WordsLog postLog={postLog} currentWords={currentWords} setFocus={setFocus} />
        )}

        {/* code list component */}
        {settingsNav === "projects" && (
          <CodeListSettings
            selectedProjects={selectedProjects}
            listItem={listItem}
            setListItem={setListItem}
            addListItem={addListItem}
            removeListItem={removeListItem}
            dark={dark}
            setFocus={setFocus}
          />
        )}

        {/* upload photo dash */}
        {/* code list component */}
        {settingsNav === "photos" && (
          <PhotoUpload dark={dark} />
        )}

      </div>

      {/* {!hasFocus &&
        <img //eslint-disable-line
          src={dark ? "../x_w.svg" : "../x.svg"}
          alt="nav toggler"
          aria-label="open nav"
          style={{
            position: 'fixed',
            width: "42px",
            height: "auto",
            bottom: '124px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: '10',
            cursor: 'pointer'
          }}
          className={"fadeIn"}
          onClick={goHome}
        />
      } */}

    </Layout>
    )
  }

  // show error if error
  if (user && error) {
    return (
      <Layout>
        <SEO title="pad" />
        <Error />
      </Layout>
    )
  }

  // show loading... if projects not loaded yet
  if (user && loading) {
    return (
      <Layout hasFocus={hasFocus}>
        <SEO title="pad" />

        {/* <div>loading...</div> */}
        <Loader></Loader>
      </Layout>
    )
  }

  return (

    <Layout>
        <div className={dark ? "dark" : "light"}></div>
    </Layout>
    
  )
}

export default Pad
