import React, { useContext, useState, useEffect } from "react"
import dashStyles from "../styles/dashboard.module.css"
import ModeContext from "../context/ModeContext"

const IndexSettings = ({
  settings,
  updateProfilePicSetting,
  updateCTASetting,
  updateDotSetting,
  setFocus
}) => {

  const { dark } = useContext(ModeContext)
  const [dotVelocity, setDotVelocity] = useState(.5)
  const [cta, setCTA] = useState('...')
  const [payload, setPayload] = useState('https://sup.cool')

  useEffect(() => {
    setDotVelocity(settings.dotVelocity)
  }, [])

  const changeVelocity = (e) => {
    console.log(e.target.value)
    setDotVelocity(e.target.value)
  }

  const changeCTA = (e) => {
    console.log(e.target.value)
    setCTA(e.target.value)
  }

  const changePayload = (e) => {
    console.log(e.target.value)
    setPayload(e.target.value)
  }

  return (
    <div style={{ position: 'absolute', top: '32%', left: '50%', transform: 'translateX(-50%)', width: '75%' }}>
      {/* settings component */}

      <textarea type="text" rows='3' style={{ backgroundColor: dark ? 'rgba(40, 40, 40, 1)' : 'white', color: !dark ? 'rgba(40, 40, 40, 1)' : 'white', marginLeft: '10px', border: 'none', outline: 'none', fontFamily: 'monospace', width: '75%' }} placeholder={settings.cta} onFocus={setFocus} onBlur={setFocus} onChange={(e) => {
        changeCTA(e);
      }} />

      <textarea type="text" rows="1" style={{ backgroundColor: dark ? 'rgba(40, 40, 40, 1)' : 'white', color: !dark ? 'rgba(40, 40, 40, 1)' : 'white', marginLeft: '10px', marginTop: '10px', border: 'none', outline: 'none', fontFamily: 'monospace', width: '75%' }} placeholder={settings.payload} onFocus={setFocus} onBlur={setFocus} onChange={(e) => {
        changePayload(e);
      }} />

      <br />

      <p className={dashStyles.settingsText} style={{ marginTop: '20px' }}>post</p>
      <input
        type="checkbox"
        className={dashStyles.settingsInput}
        onChange={() => {
          updateCTASetting(cta, payload)
        }}
        checked={settings.showCTA}
      />


      {/* <p style={{marginBottom: '15px'}}>settings</p> */}

      <p style={{ fontSize: '11px', opacity: '.5' }}>{dotVelocity * 10}</p>
      <input type="range" min="0" max="1" step=".1" value={dotVelocity} style={{ marginBottom: '10px' }} onChange={(e) => {
        changeVelocity(e);
      }} />


      <br />


      <p className={dashStyles.settingsText}>dots</p>
      <input
        type="checkbox"
        className={dashStyles.settingsInput}
        onChange={() => {
          updateDotSetting(dotVelocity)
        }}
        checked={settings.showDots}
      />

      <br />

      <p className={dashStyles.settingsText}>log</p>{" "}
      <input
        type="checkbox"
        className={dashStyles.settingsInput}
        onChange={updateProfilePicSetting}
        checked={settings.showProfilePic}
      />

    </div>
  )
}

export default IndexSettings
