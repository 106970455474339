const axios = require("axios")
const peaceUrl = "https://api.sup.cool/peace/"
// const peaceUrl = "http://localhost:3000/peace"

export const getPeace = async () => {
  
  const peace = await axios.get(peaceUrl)

  const peaceCount = peace.data.peace.count;
//   console.log(words)

  return peaceCount
}

export const incrementPeaceCount = async () => {

  // console.log(newLog)
  await axios.post(peaceUrl)
}