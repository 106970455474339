const axios = require("axios")
const logUrl = "https://api.sup.cool/log/"
// const logUrl = "http://localhost:3000/log"

export const getLog = async (token) => {

  const config = {
    headers: { Authorization: `bearer ${token}` },
  }
  
  const words = await axios.get(logUrl, config)

  const log = words.data.log;
//   console.log(words)

  return log
}

export const setLog = async (words, status, token) => {

  const dateObj = new Date();
  // let options = {day: '2-digit', year: '2-digit', month: '2-digit'}
  let options = {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'}
  let date = dateObj.toLocaleDateString("en-us", options).split(',').join('');
  let hours = dateObj.getHours() < 10 ? `0${dateObj.getHours()}` : dateObj.getHours();
  let minutes = dateObj.getMinutes() < 10 ? `0${dateObj.getMinutes()}` : dateObj.getMinutes();
  let seconds = dateObj.getSeconds() < 10 ? `0${dateObj.getSeconds()}` : dateObj.getSeconds();
  let time = hours + ":" + minutes + ":" + seconds;

  const config = {
    headers: { Authorization: `bearer ${token}` },
  }

  const newLog = {
    date,
    time,
    log: words,
    status
  }

  // console.log(newLog)
  await axios.post(logUrl, newLog, config)
}
