import React, {useState, useContext, useEffect} from "react"
import dashStyles from "../styles/dashboard.module.css"

import ModeContext from "../context/ModeContext"

const WordsLog = ({ postLog, currentWords, setFocus }) => {
  
  useEffect(() => {
    setCurrent(currentWords.log)
    setStatus(currentWords.status)
    // console.log(currentWords.status)
  }, [])

  const [log, setLog] = useState("")
  const [current, setCurrent] = useState("")
  const [status, setStatus] = useState("")

  const { dark } = useContext(ModeContext)

  const blurIfEnterKey = (e) => {
    
      console.log(e.key, e.keyCode)
    if (e.key === '.' || e.keyCode === 190) {
        e.preventDefault()
        e.currentTarget.blur();
    }
  }

  return (
    <div style={{position: 'absolute', top: '36%', left: '50%', transform: 'translateX(-50%)'}}>

        {/* <p style={{marginBottom: '6px'}}>log</p> */}
        {/* <p style={{marginBottom: '14px', fontSize: '14px'}}>{current}</p> */}

        <textarea name="log" id="log" cols="40" rows="2" placeholder={current ? current : ""} value={log} 
            onKeyPress={(e) => {
                blurIfEnterKey(e)
            }} 
            onChange={(e) => {
            setLog(e.target.value)
            }}
            onFocus={setFocus}
            onBlur={setFocus}
            style={{
                border: 'none',
                outline: 'none',
                textAlign: 'center',
                background: dark ? 'rgb(40, 40, 40)' : 'white',
                color: dark ? 'white' : 'black',
                fontFamily: 'monospace' 
            }}
        ></textarea>

        { status && <fieldset style={{border: 'none', display:'block', margin:'auto', marginTop: '12px', fontSize: '14px', fontFamily:'Arial, Helvetica, sans-serif'}}>

            <div style={{display: 'inline-block', marginRight: '8px', marginBottom: '4px'}}>
            <input type="radio" id="dnd" name="status" value="dnd" onClick={() => {setStatus("dnd")}} defaultChecked={status === "dnd" ? true : false}/>
            <label htmlFor="dnd" style={{marginLeft: '4px'}}>dnd</label>
            </div>
            
            <div style={{display: 'inline-block', marginRight: '8px', marginBottom: '4px'}}>
            <input type="radio" id="busy" name="status" value="busy" onClick={() => {setStatus("busy")}} defaultChecked={status === "busy" ? true : false}/>
            <label htmlFor="busy" style={{marginLeft: '4px'}}>busy</label>
            </div>

            <div style={{display: 'inline-block', marginRight: '8px', marginBottom: '4px'}}>
            <input type="radio" id="free" name="status" value="free" onClick={() => {setStatus("free")}} defaultChecked={status === "free" ? true : false}/>
            <label htmlFor="free" style={{marginLeft: '4px'}}>free</label>
            </div>

            <div style={{display: 'inline-block', marginRight: '8px', marginBottom: '4px'}}>
            <input type="radio" id="chillin" name="status" value="chillin" onClick={() => {setStatus("chillin")}} defaultChecked={status === "chillin" ? true : false}/>
            <label htmlFor="chillin" style={{marginLeft: '4px'}}>chillin</label>
            </div>

            <div style={{display: 'inline-block', marginRight: '8px', marginBottom: '4px'}}>
            <input type="radio" id="sleeping" name="status" value="sleeping" onClick={() => {setStatus("sleeping")}} defaultChecked={status === "sleeping" ? true : false}/>
            <label htmlFor="sleeping" style={{marginLeft: '4px'}}>sleeping</label>
            </div>
        </fieldset>}


        {/* <div className={dashStyles.buttonContainer}> */}
            {/* <button 
                className={
                    dark
                    ? `button buttonDark ${dashStyles.addButton}`
                    : `button ${dashStyles.addButton}`
                }
                onClick={() => {
                    postLog(log, status)
                    setCurrent(log)
                    setLog("")

                }}
            >
                post
            </button>

            <button  
                className={dark ? `button buttonDark` : `button`}
                style={{
                        width: "72px",
                        height: "28px",
                }}
                onClick={() => {
                    postLog("...", status)
                    setCurrent("...")
                    setLog("")

                }}
                >
                clear
            </button> */}

            <div className={dashStyles.container}>
            <button className={dashStyles.pushable} id='button' onClick={() => {
                
                if (!log) {
                    postLog("... .._ .__.", status)
                    setCurrent("... .._ .__.")
                } else {
                    postLog(log, status)
                    setCurrent(log)
                }
                setLog("")
                }} onTouchStart={() => {navigator.vibrate(34)}}>
                <span className={dashStyles.shadow} style={dark ? {background: "hsl(145deg 63% 49% / 0.25)"} : {background: "hsl(0deg 0% 0% / 0.25"}}></span>
                <span className={dashStyles.edge}></span>
                <span className={dashStyles.front} id='front'>
                </span>
            </button>
            </div>

        {/* </div> */}
    


    </div>
  )
}

export default WordsLog