import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useContext, useEffect } from "react"
import headerStyles from "../styles/header.module.css"
// import MobileNav from "../components/mobileNav"
import SettingsContext from "../context/SettingsContext"
import ModeContext from "../context/ModeContext"
import { incrementPeaceCount } from "../services/peace"
import weatherService from "../services/weather"


const Header = ({ dark, peaceCount }) => {
  const [showNav, setShowNav] = useState(false)
  const [hideNav, setHideNav] = useState(false)
  const [burger, setBurger] = useState(true)
  const [oneUp, setOneUp] = useState(true)
  // const [countGoUp, setCountGoUp] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [showCount, setShowCount] = useState(false)
  const [weather, setWeather] = useState("")

  useEffect(() => {
    const lastVisit = localStorage.getItem("peaceTime")
    const loggedIn = localStorage.getItem("loggedInUser")
    if (loggedIn) { setLoggedIn(true) }
    // console.log(loggedIn)
    const dateObj = new Date()
    const timeNow = dateObj.getTime()
    getAndSetWeather()

    if (timeNow - lastVisit < 7200000 || loggedIn) {
      setOneUp(false)
    }
  }, [])

  const nod = e => {
    if (e.shiftKey) {
      e.preventDefault()
      navigate("/pad/")
    }
  }

  const getAndSetWeather = async () => {
    const weatherData = await weatherService.getWeather()
    setWeather(weatherData)
  }


  const { toggleDark } = useContext(ModeContext)
  const { settings } = useContext(SettingsContext)

  const toggleNav = () => {
    // super secret link to dashboard
    if (!showNav) {
      setBurger(!burger)
      setShowNav(!showNav)
    } else {
      setBurger(!burger)
      setHideNav(true)
      setTimeout(() => {
        setShowNav(!showNav)
        setHideNav(false)
      }, 900)
    }
  }

  const toggleOneUp = async (e) => {
    setOneUp(!oneUp)
    // setCountGoUp(true)

    navigator.vibrate([100, 120, 100, 120, 100, 240, 100, 120, 100, 120, 300, 240, 100, 120, 300, 120, 300, 120, 100])

    let dateObj = new Date()
    const timeNow = dateObj.getTime()
    localStorage.setItem("peaceTime", timeNow)
    await incrementPeaceCount()
  }

  const toggleShowCount = () => {
    setShowCount(true)
    navigator.vibrate([100, 120, 100, 120, 100, 240, 100, 120, 100, 120, 300, 240, 100, 120, 300, 120, 300, 120, 100])
    setTimeout(() => {
      setShowCount(false)
    }, 2000)
  }

  const logOFf = () => {

    navigator.vibrate([100, 120, 100, 120, 100, 240, 100, 120, 100, 120, 300, 240, 100, 120, 300, 120, 300, 120, 100])

    setTimeout(() => {
      if (window !== "undefined") {
        window.localStorage.removeItem("loggedInUser")
        window.localStorage.removeItem("dash")
        navigate('/treefort_45001')
      }
    }, 3000)

  }

  const width = typeof window !== "undefined" ? window.innerWidth : null

  // mobile navabar, should prob move to own component
  // if (width <= 500) {
  //   return (
  //     <>
  //       <header>
  //         <div>
  //           <Link to="/" className={headerStyles.logo} onClick={nod}>
  //             <img src={dark ? "../sup_w.svg" : "../sup.svg"} alt="sup." />
  //           </Link>
  //           {burger ? (
  //             <i
  //               className={`fas fa-ellipsis-v ${headerStyles.menuIcon}`}
  //               style={dark ? { color: "white" } : { color: "black" }}
  //               onClick={toggleNav}
  //               onKeyDown={toggleNav}
  //               role="button"
  //               tabIndex={0}
  //               aria-label="open nav"
  //             ></i>
  //           ) : (
  //             <img //eslint-disable-line
  //               src={dark ? "../x_w.svg" : "../x.svg"}
  //               alt="nav toggler"
  //               onClick={toggleNav}
  //               onKeyDown={toggleNav}
  //               aria-label="open nav"
  //               style={{
  //                 width: "42px",
  //                 height: "auto",
  //                 transform: "translate(46px, -6px)",
  //               }}
  //             />
  //           )}
  //         </div>
  //       </header>
  //       <MobileNav
  //         show={showNav}
  //         hide={hideNav}
  //         setHideNav={setHideNav}
  //         setShowNav={setShowNav}
  //       />
  //     </>
  //   )
  // }

  // desktop navbar
  return (
    <>
      <header >
        <div>
          <Link to="/" className={headerStyles.logo} style={{ zIndex: '100' }}>
            {/* <img src={dark ? "../sup_w.svg" : "../sup.svg"} alt="sup." /> */}
            {/* <img src={dark ? "../sup_logo_word_tb_white.gif" : "../sup_logo_word_tb.gif"} alt="sup." /> */}
            <img style={{transform: 'translateY(-12px)'}} src={dark ? "../sup_tb_w.png" : "../sup_tb.png"} alt="sup." />
          </Link>
          {/* <img className={headerStyles.dot} src={dark ? "../sup_logo_dot_tb.gif" : "../sup_logo_dot_tb.gif"} alt="sup." style={{ animationDelay: '1s' }} /> */}

          {weather ?
            <>
              <p className="fadeIn" style={{ fontSize: '12px', marginTop: '54px', marginLeft: '18px', opacity: '.75', textAlign: 'left', cursor: 'default' }} >{`${weather.weather.location.toLowerCase()}`}</p>
              <p className="fadeIn" style={{ fontSize: '12px', marginTop: '0px', marginLeft: '18px', opacity: '.75', textAlign: 'left', cursor: 'default' }} >{`${weather.weather.temp} and ${weather.weather.desc.toLowerCase()}`}</p>
            </>
            :
            <></>
          }


          {/* <button
            className={dark ? `penDark` : `pen`}
            onClick={() => {
              window.localStorage.setItem("dash", "log")
              navigate('/pad')
            }}
          >
            <i className="far fa-edit"></i>
          </button> */}


          {/* {countGoUp && <p className="upCount">{peaceCount+1}</p>} */}
          {/* {showCount && <p className="upCount">{peaceCount}</p>} */}
          <button
            className={dark ? `oneUpDark` : `oneUp`}
            style={{ opacity: !loggedIn && !oneUp ? .32 : '' }}
            onClick={(e) => {
              // if (oneUp) {
              //   toggleOneUp(e)
              // }
              // if (loggedIn) {
              //   toggleShowCount()
              // }
              logOFf()
            }
            }
          >

            <i className="far fa-hand-peace"></i>
          </button>

          {/* <button
            className={dark ? `gearDark` : `gear`}
            onClick={() => {
              window.localStorage.setItem("dash", "settings")
              navigate('/pad')
            }}

          >
            <i className="fas fa-sliders-h"></i>
          </button> */}

          <button
            className={dark ? `toggleDark` : `toggle`}
            onClick={() => toggleDark()}

          // className={`toggleBroken`}
          // onClick={(e) => {
          //   e.target.style.color = dark ? '' : ''
          // }}
          >
            <i className="far fa-lightbulb"></i>
          </button>

        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
