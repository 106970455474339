import axios from "axios"
// const baseUrl = "https://sup-cool.herokuapp.com/api/login"
const baseUrl = "https://api.sup.cool/login"

const login = async credentials => {
  const response = await axios.post(baseUrl, credentials)
  return response.data
}

export default { login }
