import React from "react"
import dashStyles from "../styles/dashboard.module.css"

const CodeListSettings = ({
  selectedProjects,
  listItem,
  setListItem,
  addListItem,
  removeListItem,
  dark,
  setFocus
}) => {
  return (
    <div style={{position: 'absolute', top: '36%', left: '50%', transform: 'translateX(-50%)'}}>
      {/* update code list component */}
      {/* <p>projects</p> */}
      <p className={dashStyles.settingsText} style={{ marginTop: "6px", width: '350px', display:'block', marginBottom: '14px' }}>
        {selectedProjects.join(" + ")}
      </p>
      {/* <p className={dashStyles.settingsText}>update list:</p> */}
      <input
        type="text"
        placeholder="project"
        style={{ width: "350px" , outline: 'none', border: 'none', textAlign: 'center', background: dark ? 'rgb(40, 40, 40)' : 'white', color: dark ? 'white' : 'black', fontFamily: 'monospace'}}
        value={listItem}
        onChange={e => {
          setListItem(e.target.value)
        }}
        onFocus={setFocus}
        onBlur={setFocus}
      />
      <div className={dashStyles.buttonContainer}>
        <button
          className={
            dark
              ? `button buttonDark ${dashStyles.addButton}`
              : `button ${dashStyles.addButton}`
          }
          style={{fontSize: '26px'}}
          onClick={addListItem}
        >
          +
        </button>
        <button
          className={dark ? `button buttonDark` : `button`}
          style={{
            width: "72px",
            height: "28px",
            fontSize: '26px'
          }}
          onClick={removeListItem}
        >
          -
        </button>
      </div>
    </div>
  )
}

export default CodeListSettings
