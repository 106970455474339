import React, {useState} from "react"
import dashStyles from "../styles/dashboard.module.css"
import axios from "axios"

const PhotoUpload = ({dark}) => {

  const [imagePreview, setImagePreview] = useState("")
  const [imageFile, setImageFile] = useState(null)
  const [imageTitle, setImageTitle]=  useState("")
  const [imageLocation, setImageLocation] = useState("")
  const [imageDate, setImageDate] = useState("")
  const [notice, setNotice] = useState('')

  const baseUrl = "http://localhost:3000/api/uploads"

  const ref = React.useRef();

  const handleImage = (e) => {
      let imageAsBase64 = URL.createObjectURL(e.target.files[0])
      let imageAsFile = e.target.files[0]


      setImagePreview(imageAsBase64)
      setImageFile(imageAsFile)
  }

  const clearForm = (e) => {
    if (e) {e.preventDefault()}

    ref.current.value = ''
    setImagePreview('')
    setImageFile(null)
    setImageTitle('')
    setImageLocation('')
    setImageDate('')

  }

  const uploadFile = async (e) => {
    e.preventDefault();

    if (!imageFile) {
        setNotice('Select an image!')

        setTimeout(() => {
            setNotice('')
        }, 2000)

        return
    }

    const formData = new FormData();

    formData.append('photo', imageFile);
    formData.append('title', imageTitle);
    formData.append('location', imageLocation);
    formData.append('date', imageDate);

    try {
        const res = await axios.post(baseUrl, formData, {headers: {"Content-Type": "multipart/form-data"}})

        console.log(res.data)

        setNotice(res.data)

        setTimeout(() => {
            setNotice('')
        }, 2000)


        clearForm()

    } catch(err) {
        console.log(err)

        setNotice('Something went wrong...')

        setTimeout(() => {
            setNotice('')
        }, 2000)
    }
  }

  return (
    <>
      <p style={{textAlign: 'center', fontSize: '18px'}}>{notice}</p>
      {imagePreview && <img src={imagePreview} alt="upload preview" style={{height: '150px', width: 'auto'}}/>}

      <form encType="multipart/form-data" style={{position: 'absolute', top: '34%', left: '50%', transform: 'translateX(-50%)'}}>
        <input type="file" name="photo" ref={ref} className={dashStyles.uploadItem} onChange={e => handleImage(e)}/>
        <input type="text" name="title" placeholder="title" className={dashStyles.uploadItem} value={imageTitle} onChange={e => setImageTitle(e.target.value)}/>
        <input type="text" name="location" placeholder="location" className={dashStyles.uploadItem} value={imageLocation} onChange={e => setImageLocation(e.target.value)}/>
        <input type="text" name="date" placeholder="date" className={dashStyles.uploadItem} value={imageDate} onChange={e => setImageDate(e.target.value)}/>
        <input type="submit" value="submit" onClick={e => uploadFile(e)} className={
            dark
              ? `button buttonDark ${dashStyles.addButton}`
              : `button ${dashStyles.addButton}`
          }/>
          <button onClick={e => clearForm(e)} className={
            dark
              ? `button buttonDark ${dashStyles.addButton}`
              : `button ${dashStyles.addButton}`
          }>clear</button>
      </form>
    </>
    
  )
}

export default PhotoUpload