import React, { useState, useContext } from "react"
import ModeContext from "../context/ModeContext"
import Alert from "./alert"
import loginStyles from "../styles/login.module.css"
import loginService from "../services/login"
import Loader from './loader'
import { navigate } from "gatsby"

const Login = ({ setCurrentUser, setFocus }) => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [showAlert, setShowAlert] = useState(false)
  const [alert, setAlert] = useState({})

  const { dark } = useContext(ModeContext)
  // const dark = true;

  //  make this a component and import into dashboard page to show if not logged in

  const login = async e => {
    e.preventDefault()
    const form = e.target

    if (!username || !password) {
      setAlert({
        success: false,
        msg: `no...`,
      })
      setShowAlert(true)

      setTimeout(() => {
        setShowAlert(false)
        setAlert({})
        navigate('/')
      }, 2500)
      return
    }

    try {
      const user = await loginService.login({
        username,
        password,
      })

      setAlert({
        success: true,
        msg: `morning sir...`,
      })
      setShowAlert(true)
      form.setAttribute("class", `${loginStyles.hideForm}`)
      // maybe add form.blur() here, might not need setFocus then, and will hide keyboard on mobile submit
      // setFocus()
      // form.blur()

      setTimeout(() => {
        setShowAlert(false)
        setAlert({})
        if (window !== "undefined") {
          window.localStorage.setItem("loggedInUser", JSON.stringify(user))
        }
        setUsername("")
        setPassword("")
        // setCurrentUser(user)
        // window.location.reload()
        navigate('/notebook_5183')
      }, 1200)
    } catch (exception) {
      console.error(exception)
      const buds = ['Chachi', 'Joben', 'Dingus', 'Malfoy', 'Potter', 'Hiro', 'Sir', 'Dude', 'Lady', "Ma'am", 'Bud', 'Friend']
      const bud = buds[Math.floor(Math.random() * ((buds.length - 1) - 0) + 0)]
      setAlert({
        success: false,
        msg: `nope...`,
      })
      // console.log(Math.random() * ((buds.length-1)-0) + 0)
      // setUsername("")
      setPassword("")
      setShowAlert(true)

      setTimeout(() => {
        setShowAlert(false)
        setAlert({})
      }, 2500)
    }
  }

  return (
    <>
      {/* <Loader></Loader> */}
      <div className={loginStyles.wrap}>

        <form onSubmit={login} className={loginStyles.form}>
          {showAlert && <Alert alert={alert} />}
          <input
            type="text"
            className={loginStyles.input}
            style={{ top: "50px" }}
            value={username}
            placeholder="name"
            onChange={({ target }) => setUsername(target.value)}
            onFocus={setFocus}
            onBlur={setFocus}
          />
          <input
            type="password"
            className={loginStyles.input}
            style={{ top: "100px" }}
            value={password}
            placeholder="password"
            onChange={({ target }) => setPassword(target.value)}
            onFocus={setFocus}
            onBlur={setFocus}
          />
          <button
            onTouchStart={() => { navigator.vibrate(100) }}
            style={(username && password) ? { opacity: .86, backgroundColor: 'rgba(60, 70, 92, .95)' } : { filter: 'brightness(1)', boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.5)', marginTop: '8px', transform: 'translateX(-50%) scale(1)', backgroundColor: 'rgba(60, 70, 92, .75)' }}
            className={
              `button ${loginStyles.login}`
            }
          // className={
          //   !dark
          //     ? `button ${loginStyles.login}`
          //     : `button buttonDark ${loginStyles.login}`
          // }
          >
            <span style={{ opacity: '1', color: 'white' }}>{"__________"}</span>
          </button>
        </form>
      </div>

      {/* < i className="fas fa-user-circle" style={{ zIndex: '20', color: 'rgba(60, 70, 92, 1)', fontSize: '18px', position: 'absolute', left: '50%', transform: 'translate(-50%)', bottom: '35px', opacity: '.65' }} onClick={() => { navigate('/') }}></i > */}
    </>
  )
}

export default Login
